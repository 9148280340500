/* Breakpoints */

$tabletportrait: 768px;
$tabletLandscape: 1024px;

/* Colors */

$primaryColor: #778077;
$primaryTextColor: #FFFFFF;

