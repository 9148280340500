.loader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    z-index: 110;
    opacity: 0;
    transition: all ease-out 0.3s;
    pointer-events: none;
    transition-delay: 0.3s;

    .loaderSpinner {
        position: fixed;
        left: 50%;
        top: 50%;
        width: 40px;
        height: 40px;     
        transform: translate(-50%, -50%);
        z-index: 120;   
    }

    &.show {
        opacity: 1;
        pointer-events: unset;
        transition-delay: 0;
        transition-duration: 0.1s;
    }
}
