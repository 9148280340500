* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Calibre', Helvetica,Arial,sans-serif;
  font-size: 14px;
}

@font-face {
  font-family: 'Hoboken';
  src: url('./fonts/Hoboken-Serial-Medium-Regular.ttf');
  src: url('./fonts/hoboken-serial-medium-regular-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'Calibre';
  src: url('./fonts/CalibreWeb-Light.eot');
  src: url('./fonts/CalibreWeb-Light.eot?#iefix') format('embedded-opentype'),url('./fonts/CalibreWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: 'Calibre';
  src: url('./fonts/CalibreWeb-LightItalic.eot');
  src: url('./fonts/CalibreWeb-LightItalic.eot?#iefix') format('embedded-opentype'),url('./fonts/CalibreWeb-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic
}

@font-face {
  font-family: 'Calibre';
  src: url('./fonts/CalibreWeb-Regular.eot');
  src: url('./fonts/CalibreWeb-Regular.eot?#iefix') format('embedded-opentype'),url('./fonts/CalibreWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Calibre';
  src: url('./fonts/CalibreWeb-RegularItalic.eot');
  src: url('./fonts/CalibreWeb-RegularItalic.eot?#iefix') format('embedded-opentype'),url('./fonts/CalibreWeb-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: 'Calibre';
  src: url('./fonts/CalibreWeb-Medium.eot');
  src: url('./fonts/CalibreWeb-Medium.eot?#iefix') format('embedded-opentype'),url('./fonts/CalibreWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'Calibre';
  src: url('./fonts/CalibreWeb-MediumItalic.eot');
  src: url('./fonts/CalibreWeb-MediumItalic.eot?#iefix') format('embedded-opentype'),url('./fonts/CalibreWeb-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  cursor: pointer;
}

ul,ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;  
  border: 0 none;
  font-family: 'Calibre', Helvetica,Arial,sans-serif;
  color: #000;
  cursor: pointer;  
}

@keyframes spinner {
  to { transform: rotate(360deg); }
} 

.spinner {
  animation: spinner 1s linear infinite;
}