@import "../variables.scss";

.hotspots {
    position: relative;
    height: 100%;
    width: 100%;
    opacity: 0;  
    transition: opacity .4s ease-in-out;
    z-index: 10;

    &.show {
        opacity: 1;       
    }

    .hotspot {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        width: 40px;
        height: 40px;
        background-color: rgba($primaryColor, 1);
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform .2s ease-out, background-color .2s ease-out;
        cursor: pointer;

        svg {
            path {
                fill: rgba(255, 255, 255, 1);
            }
        }

        &:hover {
            transform: scale(1.2, 1.2);           
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            display: block;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            display: none;
        }
    }

    .hotspot-label {
        padding: 0.5rem 1rem;
        position: absolute;
        width: 100px;
        display: none;
        text-align: center;
        background-color: rgba(255, 255, 255, 1);
        color: rgba(0, 0, 0, 1);
        z-index: 20;
        opacity: 0;
        transition: opacity .2s ease-out;
        text-transform: capitalize;

        &:after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-top-color: #ffffff;
            border-width: 10px;
            margin-left: -10px;
        }
    }
}