@import "../variables.scss";

.angle-menu {    
    display: flex;   
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 150px;
    z-index: 110;

    @media screen and (max-width: $tabletLandscape) {      
        width: 100px;
    }

    ul {
        padding: 10px;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        li { 
            margin-bottom: 20px;          

            button {
                position: relative;
                display: inline-block;       
                width: 100%;
                height: auto;
                justify-content: center;
                align-items: center;                
                text-transform: capitalize;              
                transition: opacity .2s ease-out;
           
                img {
                    display: block;
                    width: 100%;                    
                    height: auto;
                    transition: opacity .2s ease-out;
                }

                &:after {
                    content : "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border: 4px solid transparent;  
                }

                span {
                    padding: 0.5em 0;
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                    width: 100%;
                    opacity: 0;
                    font-size: 1em;
                    text-transform: uppercase;
                    background-color: rgba($primaryColor,1);
                    color: $primaryTextColor;
                    transition: opacity .2s ease-out;
                    word-break: break-word;

                    @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
                        font-size: 0.75em;
                    }
                }

                &:hover,
                &.selected {
                    &:after {
                        border: 4px solid rgba($primaryColor,1);                          
                    }

                    .menu-img-wrapper {
                        &:after {
                            opacity: 1;                            
                        }
                    }
    
                    img {
                        opacity: 0.4;
                    }
    
                    span {
                        opacity: 1;
                    }
                }
    
            }
        }
    }
}