@import "../variables.scss";

.action-menu {    
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 110;

    .action-menu-top {
       flex: 1;
    }

    .action-menu-bottom {
       flex: 0;
    }

    .action-button {    
        margin-top: 20px;  
        margin-bottom: 20px;
        margin-right: 20px;
        width: 50px;
        height: 50px;
        background-color: rgba($primaryColor, 1);
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primaryTextColor;
        transition: transform 0.2s ease-out;
        box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2); 
        -webkit-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2); 
        -moz-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2); 
        transition: transform .2s ease-out,  color .2s ease-out, background-color .2s ease-out;
        cursor: pointer;

        &:hover {         
            transform: scale(1.2,1.2);
            background-color: rgba(255, 255, 255, 1); 
            color: invert($primaryTextColor);           
        }   
    }
}