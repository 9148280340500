@import "../variables.scss";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
   
    .modal-bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;    
        background-color: rgba(#000,0.3);
    }

    .modal-wrapper {
        margin: 2rem;
        padding: 4rem;
        position: relative;       
        background-color: rgba(#FFF,0.85);    
        text-align: center;
        max-width: 600px;
        width: 100%;
        height: auto;

        @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {           
            overflow-y: scroll;
            height: calc(100% - 100px);
        }

        .modal-close {
            position: absolute;
            top: 0;
            right: 0px;
            width: 35px;
            height: 35px;
            background-color: transparent;
        }

        .modal-header {
            h1 {
                font-family: 'Hoboken';
                font-weight: 500;
            }
        }

        .modal-content {
            font-family: 'Calibre';
            font-weight: 300;
            font-size: 1.1em;

            p {
                margin: 0;
                padding: 0;
            }
        }
    }
}