@import "../variables.scss";

.visualization {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #eee;
    transition: all ease-in-out 0.25s;

    img {
        object-fit: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; 
        width: 100%;
        height: 100%;
        transition: all ease-in-out 0.25s;
        font-family: 'object-fit: cover;';

       /* @media screen and (max-width: $tableportrait), screen and (max-height: 414px) and (orientation: landscape) {      
            object-fit: contain
        }*/

        &.loading {
            opacity: 0;
        }
    }   
}
